.gallery {
    .galleryItem{
        height: 375px;
        flex: 1;
        background-size: cover;
        background-repeat: no-repeat;
        background-position:center;
        margin: 2px;
        transition: 0.5s;
        overflow: hidden;
        

        &:hover {
            flex:  50%;  
            .overlay {
                opacity: 1;
            }
            // &:nth-child(1) {
            //     .overlay {
            //         background-color: rgba($color: #FF6382, $alpha: 0.9);
            //     } 
            // }
            // &:nth-child(2) {
            //     .overlay {
            //         background-color: rgba($color: #9185DB, $alpha: 0.9);
            //     } 
            // }
            // &:nth-child(3) {
            //     .overlay {
            //         background-color: rgba($color: #38D996, $alpha: 0.9);
            //     } 
            // }
            // &:nth-child(4) {
            //     .overlay {
            //         background-color: rgba($color: #FFAC9E, $alpha: 0.9);
            //     } 
            // }
            
        }
        .overlay {
            opacity: 0;
            @extend .pl40;
            @extend .pr40;
            @extend .pb20;
            height: 100%;
            width: 100%;
            display: flex;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.05) 85%);
            align-items: flex-end;
            justify-content: left;
           color: $white;
            .gallery-title{
               width: 100%;
               @extend .fs22;
               @extend .mb5;
            }
        }
        &:first-child{
            border-radius: 50px 0px 0px 50px;   
        }
        &:last-child{
            border-radius:  0px 50px 50px 0px;
        }
    }
}