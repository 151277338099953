.app-footer{
    overflow: hidden;
    background-color: #fff;
    border-radius: 90px 90px 0px 0px;
    box-shadow: 0px -2px 5px rgb(235, 232, 232);
     @extend .p30;
    position: relative;
     &::after{
         content: 'Footer';
         font-size: 180px;
         position: absolute;
         right: 20px;
         bottom: -80px;
         @extend .heading;
         opacity: 0.05;
     }

     .section-list {
         margin: auto;
         li{
             @extend .ml20, .mr20;
             @extend .fs14;
             a{
                color: rgba(0,0,0,0.5);
                font-weight: 600;
                &:hover{
                    text-decoration: none;
                }
            
             }
             position: relative;

             &:not(:last-child){
                &::after{
                    content: '/';
                    position: absolute;
                    right: -20px;
                    color:black;
                    @extend .fs12;
                    top: 3px;
                }
             }
             
         }
     }

     .social-media {
         li{

            &:hover{
                a{
                    
             text-decoration: none;
                border-radius: 0% 50% 50% 50%;
                
                }
            }
            
            a{
                height: 30px;
                width: 30px;
                border-radius: 50% 50% 0 50%;
                @extend .ml10, .mr10;
                @include center-middle;
                background-color: grey;
                color: white;
                @extend .fs14;
                transition: 1s;
               
            
                &.fb {
                    background-color: #1975ED;
                }
                &.gp {
                    background-color: #D64836;
                }
                &.ins {
                    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
                }
                &.twt {
                    background-color: #1DA1F2;
                }
            }

             

         }
     }
    
}

.sub-footer {
    background-color: rgba(0,0,0,0.02);
    @extend .pt10, .pb10;
    .beating{
        animation: heartbeat 1s infinite;
        @extend .ml5, .mr5;
    }
    
    .credits-text {
        color: #737373;
        @extend .fs12;
    }
}


@-webkit-keyframes heartbeat {
	0% {
        transform: scale(.75);
        color: #F70000;
	}
	20% {
        transform: scale(1);
        color: #F70000;
	}
	40% {
        transform: scale(.75);
        color: #F70000;
	}
	60% {
        transform: scale(1);
        color: #F70000;
	}
	80% {
        transform: scale(.75);
        color: #F70000;
	}
	to {
        transform: scale(.75);
        color: #F70000;
	}
}


@keyframes heartbeat {
    0% {
        transform: scale(.75);
        color: #F70000;
	}
	20% {
        transform: scale(1);
        color: #F70000;
	}
	40% {
        transform: scale(.75);
        color: #F70000;
	}
	60% {
        transform: scale(1);
        color: #F70000;
	}
	80% {
        transform: scale(.75);
        color: #F70000;
	}
	to {
        transform: scale(.75);
        color: #F70000;
	}
}
