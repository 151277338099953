.service-cards {
    box-shadow: 0px 1px 4px #ddd;
    &.card{
        border: $val-0;
        border-radius: 1rem;
        min-height: 275px;
        transition: 0.5s ease;
        .card-body{
            padding: 35px;
            .desc {
                color: $grey3;
                line-height: 24px;
                @extend .fs14;
            }
        }

        &:hover {
            transform: translate(0px, -5px);    
            box-shadow: 0px 5px 15px #ddd;
        }
    }
    
    .icon{
       height: $val-60;
       width: $val-60;
       @include center-middle;
       @extend .fs28;
       border-radius: $val-10;
       &.purple {
        background-color: $light-purple;
        color: $purple; 
       }
       &.orange {
           background-color: $light-orange;
           color: $orange;
       }
       &.blue{
           background-color: $light-blue;
           color: $blue;
       }

       &.green {
           background-color: $light-green;
           color: $green;
       }

       &.navy-blue {
           background-color: $light-yellow;
           color: $yellow;
       }

       &.pink {
           background-color: $light-pink;
           color: $pink;
       }
    }

    .icon-img{
        width: 28px;
    }
}


