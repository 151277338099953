
.lh-46{
    line-height: 50px !important;
}

// Floating Input Form

.custom-select,
.form-control{
    &:focus{
        outline: 0;
        box-shadow: none !important;
    }
}

@mixin float-focus-label{
    top: 5px;
    font-size:11px;
    z-index: 1;
    font-weight: 500;
    color: $dark-black;
    @extend .ml4;
}

.floating-input {
    background-color: transparent;
    z-index: 2;
    border:1px solid $grey7;
    
    border-radius: 8px;
    height: 50px !important;
    .form-control {
      margin-top: 18px;
      @extend .fs12;
      @extend .pl14;
      color: $dark-grey;
    }
    .floating-lable { 
        top: 13px;
        z-index: -1;
        font-size: 14px;
        color: $grey6;
        transition: .2s;
        @extend .ml8;
        .sup{
            @extend .fs13;
        }
    }

    
    select {
      @extend .fs12;
      height: 50px;
      &.custom-select {
        background: transparent url('../../images/down-arrow.svg') no-repeat right .75rem center/18px 12px;
      }
    }

    .selected {
        
        & ~ .floating-lable{
            @include float-focus-label;
        }   
    }

    &.textarea-form-input{
      height: auto !important;
    }

    textarea,
    input{
        &:focus ~ .floating-lable,
        &:not(:placeholder-shown) ~ .floating-lable{
            @include float-focus-label;
            .sup{
                color:$red;
            }
        }   
    }

    #uploadlabel ~ .floating-lable{
      top: 13px; 
      .sup{
        color:$grey5;
    }
    }

    #uploadlabel.fileuploaded ~ .floating-lable{
        @include float-focus-label;
        .sup{
            color:$red;
          }
    } 
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

textarea.form-control {
    resize: none;
}