body {
    font-family: 'Roboto', sans-serif;
}


.heading{
    font-family: 'Paytone One', sans-serif;
}

h1,h2,h3{
    @extend .heading;
}



.section-title{

    .mirror-text {
        position: absolute;
        opacity: 0.1;
        z-index: 0;
        letter-spacing: 9px;
        top: -4px;
        left: 25%;
        right: 25%;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        font-size: 30px;
    }

}

.op-9{
  opacity: 0.89;
}

@keyframes rotateinfi {
  
    from{
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }

}

@keyframes beat-infinet {
  
    0%{
        transform: scale(1.5);
    } 
    25% {
        transform: scale(1);
    } 
    75%{
      transform: scale(1.5);
    }
    100%{
        transform: scale(1.5);
      }
    
}

.left-20px{
   left: 20px;
}

.top-20px{
   top: 20px;
}

.right-20px{
  right: 20px;
} 

.top-40px {
    top: 40px;
}

.floating-icon {
    opacity: 0.15;
    font-weight: bold;
    position: absolute;
    &.roate-alternative {
        animation: rotateinfi 50s linear 2s infinite alternate;
    }

    &.roate-infinet {
        animation: rotateinfi 35s linear 2s infinite;
    }
    
    &.beat-infinet {
        animation: beat-infinet 5s linear  infinite;
    }
}


// .bg-design {
//     &::before{
//         content: '';
//         position: absolute;
//         height: 100vh;
//         width: 100vw;
// 	    border-left: 100vw solid transparent;
//         border-bottom: 100vh solid  rgba($light-blue, 0.3);    
//         right: 0%;
//     }
// }

.construnction {
    z-index: 1111;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;

    img{
        width: 300px;
    }
}