//Colors
$white:#fff;
$dark-black:#1d1d1d;

$grey:#eaeffd;
$grey2:#cbcbcb;
$grey3: #8b8888;
$grey4: #5b5b5b;
$grey5:#262626;
$grey6:#959595;
$grey7:#95959542;

$dark-grey:#757575;
$gray-dark:#707070;

$light-grey:#acacac;
$light-grey2: #f4f5f9;
$light-purple: #F5E6FE;
$light-orange: #FFE6E2;
$light-green: #b8ffe4;
$light-blue:#E3F8FA;
$light-yellow: #ffecbb;
$light-pink: #ffdae4;
$navy-blue:#013974;

$green:#2BC48A;
$green2:#02a808;
$red:#f44336;
$purple: #BE63F9;
$orange:#FC573B;
$blue:#26C6DA;
$yellow: #f9a825;
$pink: #F73A6E;

// Style variables

// value props
$val-0: 0px;
$val-10: 10px;
$val-30: 30px;
$val-35: 35px;

$val-60: 60px;

// style props
$flex: flex;
$center: center;