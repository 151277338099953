.color-grey3 {
    color: $grey3;
}

.text-black {
    color: black;
}

.color-green { color: $green; }
.color-orange { color: $orange; }
.color-red { color: $red; }
.color-blue { color: $blue; }
.color-purple { color: $purple; }
.color-yellow { color: $yellow; }
.color-pink { color: $pink; }