.step-count {
    font-size: 13.5rem;
    line-height: 8rem;
    
}

.count-title {
    &.step-1 {
      color: $light-yellow;
    }

    &.step-2 {
        color: $light-blue;     
    }

    &.step-3 {
        color: $light-orange;
    }

    &.step-4 {
        color: $light-purple;
    }

    &.step-5 {
        color: $light-green;
    }
}

.left-75p {
    left: -25%;
}

.right-75p {

    right: -25%;

}

.h-200px {
    height: 200px;
}

.mt-minus-5 {
    margin-top: -20px;
}

.broken-arrow {
    width: 170px;
    margin-top: 20px;
    opacity: 0.2;
    &.ryt {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
}

.how-do-cards {
    box-shadow: 0px 0px 2px #ddd;
 
    &.card{
        border: $val-0;
        border-radius: 1rem;
        min-height: 275px;
        
        transition: 0.5s ease;
        .card-body{
            padding: 35px;
            @include center-middle;
            height: 100%;
            flex-direction: column;
            h2{
                display: inline-block;
                margin-right: auto;
            }
            .desc {
                color: $grey3;
                line-height: 24px;
                @extend .fs14;
            }
        }

        &:hover {
            transform: scale(1.05);    
        }
        
        &.step-1-card {
            &:hover {
                box-shadow: 0px 0px 10px $light-yellow;
            }
        }
        &.step-2-card {
            &:hover {
                box-shadow: 0px 0px 10px $light-blue;
            }
        }
        &.step-3-card {
            &:hover {
                box-shadow: 0px 0px 10px $light-orange;
            }
        }
        &.step-4-card {
            &:hover {
                box-shadow: 0px 0px 10px $light-purple;
            }
        }
        &.step-5-card {
            &:hover {
                box-shadow: 0px 0px 15px $light-green;
            }
        }
    }
}