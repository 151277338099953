.home-carousel {
    background-color: #000;
    .carousel-inner {
        .carousel-item {
            .cover-img{
                min-height: 45rem;
                width: 100vw;
                background-size: cover;
                background-repeat: no-repeat;
                background-attachment: fixed;
                .overlay {
                    background-color: rgba(0,0,0,0.3);
                    min-height: 45rem;
                    z-index: 1;
                    
                }
            }
        }
    }
}


.get-a-quote-card {
    &.card{
        min-height: 400px;
        max-width: 470px;
        border-radius: 30px;
        overflow: hidden;
        box-shadow:   8px 8px 0.2px 0.5px rgba(255,255,255,0.2);;
    }
    
}

.design-1 {
    height: 45%;
    width: 25%;
    border-radius: 0px  0% 65% 0px;
    background-color: rgba(255,255,255,0.04);
    position: absolute;
    left: 0px;
    top: -10%;
}

.design-2 {
    height: 50%;
    width: 25%;
    border-radius: 50%  50% 50% 0px;
    background-color: rgba(0,0,0,0.025);
    position: absolute;
    left: 0%;
    top: 34.5%;
}

.design-3 {
    height: 65%;
    width: 25%;
    border-radius: 0%  0% 35% 0px;
    background-color: rgba(255,255,255,0.025);
    position: absolute;
    left: 25%;
    top: 0%;
}

.design-4 {
    height: 50%;
    width: 26.2%;
    border-radius: 100% 100% 0 0;
    background-color: rgba(255,255,255,0.035);
    position: absolute;
    left: 23.5%;
    top: 65%;
}

.design-5 {
    height: 80%;
    width: 25.3%;
    border-radius: 40%  0% 0% 0px;
    background-color: rgba(0,0,0,0.015);
    position: absolute;
    left: 49.7%;
    top: 20%;
}


.design-6 {
    height: 45%;
    width: 25%;
    border-radius: 0px  0% 25% 0px;
    background-color: rgba(0,0,0,0.033);
    position: absolute;
    left: 75%;
    top: -10%;
}


.design-7 {
    height: 50%;
    width: 25%;
    border-radius: 50%  40% 50% 0px;
    background-color: rgba(255,255,255,0.022);
    position: absolute;
    left: 75%;
    top: 35%;
}

.h-41rem {
    height: 41rem;
}

.sub-title {
    letter-spacing: 1px;
    opacity: 0.9;
    font-weight: 500;
}


.stats-icon {
    width: 40px;
}