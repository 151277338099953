/********* margin & padding  **********/
$spaceamounts: (0, 1, 2, 3, 4, 5, 7, 8, 10, 12, 14, 15, 18, 19, 20, 25, 30, 40, 50, 55, 59,  60, 65, 70, 80, 95, 100);
$sides: (top, bottom, left, right, all);

@each $space in $spaceamounts {
  @each $side in $sides {
    @if $side == 'all' {
      .m#{$space} {
        margin: #{$space}px;
      }

      .p#{$space} {
        padding: #{$space}px;
      }
    }

    @else {
      .m#{str-slice($side, 0, 1)}#{$space} {
        margin-#{$side}: #{$space}px;
      }

      .p#{str-slice($side, 0, 1)}#{$space} {
        padding-#{$side}: #{$space}px;
      }
    }
  }
}

/********* margin & padding  **********/



/********* Border radius ***********/

.br-10 {
  border-radius: $val-10;
}

.br-30 {
  border-radius: $val-30;
}

/********* / Border radius ***********/



/********* width & height ***********/

/********* width & height ***********/


@mixin  center-middle {
  display: $flex;
  align-items: $center;
  justify-content: $center;
}