
.br-20px {
    border-radius: 40px !important;
}

.btn {
    &.btn-cards {
        background-color: white;
        color: black;
        font-size: 14px;
        padding: 10px 20px;
        box-shadow: 0px 0px 2px #ddd;
        position: relative;
        transition: 1s;
        overflow: hidden;
        &::before {
            content: '';
            transition: 1s;
            position: absolute;
            left: -67px;
            bottom: -80px;
            height: 150px;
            width: 150px;
            transform: scale(0);
            border-radius: 50%;
            
        }
        &:hover {
            &::before {
                transform: scale(1.5);
                background-color: rgba(0,0,0,0.2);
            }
        }
    }


    &.btn-quote {
        background-color: #4885ff;
        color: white;
        font-size: 12px;
        padding: 8px 25px;
        box-shadow: 4px 4px 1px #cccccc;
        border-radius: 60px 6px 60px 60px;
        position: relative;
        letter-spacing: 2px;
        transition: 1s;
        overflow: hidden;
        &:hover{
            color: white;
            background-color: #0940AE;
            box-shadow: -0px -0px 1px #cccccc;
        }
    }
}



