@media (min-width: 769px) {
 
}

@media (min-width: 768px) {
 
}

@media (max-width: 768px) {
  
}

@media (max-width: 767px) {
    
    .navtop {
        .navbar-collapse {
            margin-top: 15px;
            background-color: white;
            border-radius: 20px;
            color: black;
            padding: 12px;
            box-shadow: 0px 0px 1px #ccc;
             .navbar-nav .nav-link{
                 color: black !important;
                 padding: 10px;
                 
             &.active{
                &:after{
                 left: 9px !important;
                }
            }
             }

        }
    }

    &.active{
        &:after{
            bottom: 0px !important;
            width: 35px !important;
        }
    }

    .gallery{
        flex-direction: column;
        
        .galleryItem {
            border-radius: 30px !important;
            height: 275px;
            margin-bottom: 20px;
            .overlay{
                opacity: 1;
                padding: 23px;
                .text-nowrap{
                    white-space: pre-wrap !important
                }
            }
        }
    }

    .smallerdesign{
        transform: translate(-15px, 0px) scale(.70);
        margin-bottom: -104px;
        margin-top: -70px;
    }
    .section-title .mirror-text{
        left: 0%;
        right: 0%;
    }
    .app-footer{
        border-radius: 50px 50px 0px 0px;
    }

    .app-footer::after {
        font-size: 101px;
        right: 20px;
        bottom: -39px;
    }
    .how-do-cards{
      margin-bottom: 20px;
    }
    .step-4-card{
        margin-bottom: 40px;
    }
    .left-75p {
        left: 15%;
    }
    
    .right-75p {
    
        right: 15%;
    
    }
}

@media (min-width: 1024px) {

}
