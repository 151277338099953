.c-pointer
{
    cursor:pointer;
}
    

@keyframes dropin {
    from{
     top: -100px;
    }
    to {
        top: -0px;
    }
}


.custom-navbar {
   .navbar-toggler{
       border: none;
       margin-left: 20px !important;
   } 
   
    &.navbar{
        z-index: 111;
        .navbar-collapse {
          .navbar-nav {
              .nav-link {
                  margin-right: 40px;
                //   color: white;
                  @extend .fs14;
                  letter-spacing: 0.5px;
                  @extend .c-pointer;
                  &.active{
                      position: relative;
                      font-weight: bold;
                      &:after{
                          content:'';
                          bottom: -10px;
                          left: 0px;
                          right: 0px;
                          height: 3px;
                          width: 100%;
                          border-radius: 20px;
                          position: absolute;
                          background-color: red;
                      }
                  }
                  
                &.btn-getaquote {
                    background-color: transparent;
                    padding-left:20px;
                    padding-right: 20px;
                    @extend .fs14;
                    @extend .br-20px;
                    &:hover {
                        background-color: white;
                        color: black;

                    }
                }
              }
          }
        }

        &.navtop {
            background: transparent;
            position: absolute;
            color: white;
            width: 100%;
            @extend .pt20;
            @extend .pb20;

            .navbar-toggler-icon {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
            }
            .navbar-brand {
                color: white;
            }
            .navbar-collapse {
                .navbar-nav {
                    .nav-link {
                        color: white;
                        &.btn-getaquote {
                            border: 1px solid #fff !important;
                            color: #fff !important;
                            &:hover {
                                background-color: white;
                                color: black;
                            }
                        }
                    }
                }
            }
        }
        &.navscroll {
            animation-duration: 1s;
            width: 100%;
            animation-name: dropin;
            position: fixed !important;
            background: white;
            box-shadow: rgba(28, 28, 28, 0.15) 0px 2px 8px;
            @extend .pt15;
            @extend .pb15;

            .navbar-brand {
                color: #304ea2;
            }
            .navbar-collapse {
            .navbar-nav {
                .nav-link{
                   
                    &.active {
                        border-color: #304ea2;
                    }
                    
                      color: rgba(0, 0, 0, 0.5);

                      &.btn-getaquote {
                        border: 1px solid #304ea2 !important;
                        color: #304ea2 !important;
                        &:hover {
                            background-color: white;
                            color: black;
                        }
                    
                    }
                }
            } 
        } 
            .navbar-brand {
                color: rgb(54, 54, 54);;
            }
        }
    }
   
}