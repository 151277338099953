
.about-us-design {
    li{
        height: 250px;
        width: 250px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 5em;
        @include center-middle;
        flex-direction: column;
        font-size: 50px;
        transition: 1s;
        img{
            width: 50px;
        }
    }

    &.row-1 {
        li {
            &:first-child {
                border-radius: 50% 50% 0% 50%;
                color: rgba($purple, 0.6);;
                // border-color: rgba($purple, 0.2);
                background-color: $light-purple;          
            }
            &:nth-child(2) {
                border-radius: 0% 50% 50% 50%;
                color: rgba($orange, 0.6);
                // border-color: rgba($orange, 0.2);
                background-color: $light-orange;
               
            }
        }
    }

    &.row-2 {
        li {
            &:nth-child(1) {
                border-radius: 50% 50% 0% 50%; 
                color: rgba($blue, 0.6);
                // border-color: rgba($blue, 0.2);
                background-color: $light-blue; 
            }
            &:nth-child(2) {
                border-radius: 0% 50% 50% 50%;
                color: rgba($yellow, 0.6);
                // border-color: rgba($yellow, 0.2);
                background-color: $light-yellow;
            }
        }
    }
}

.circle-count {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: $grey;
    color: $grey6;
    @include center-middle;
    img{
        width: 20px;
        filter: grayscale(100%);
    }
}